import cx from 'classnames'
import { Property } from 'csstype'

import { DEFAULT_GRADIENT_ID, GRADIENT_IDS } from './svg-gradient.component'

import * as baseStyles from './svg.module.css'

interface SVGStyle {
  svg?: string
}

export interface SVGProps {
  /**
   * CSS value (i.e. '10px')
   */
  width?: string | number
  height?: string | number

  /**
   * @link https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/preserveAspectRatio
   */
  preserveAspectRatio?: string
  className?: string
  size?: string | number
  fill?: string | number
  stroke?: string
  verticalAlign?: Property.VerticalAlign<any>
  styles?: SVGStyle
  hasGradient?: boolean
  gradientId?: string
  dataTestId?: string
}

const SVG: React.FC<SVGProps> = ({
  className,
  fill,
  stroke = 'none',
  size,
  width,
  height,
  children,
  preserveAspectRatio,
  verticalAlign,
  styles,
  hasGradient = false,
  gradientId = DEFAULT_GRADIENT_ID,
  dataTestId,
}) => {
  const finalHeight = size || height
  const finalWidth = size || width
  const gradientFill = hasGradient ? `url(#${GRADIENT_IDS[gradientId]})` : ''
  const Icon = children as any

  return (
    <span
      className={cx(baseStyles.container, [className, styles && styles.svg])}
      style={{ height: finalHeight, width: finalWidth }}
      aria-hidden="true"
      data-testid={dataTestId}
    >
      <Icon
        preserveAspectRatio={preserveAspectRatio}
        height={finalHeight}
        width={finalWidth}
        fill={hasGradient ? gradientFill : fill}
        style={{
          display: 'flex',
          width: finalWidth,
          height: finalHeight,
          fill: hasGradient ? gradientFill : fill,
          stroke,
          verticalAlign,
        }}
      />
    </span>
  )
}

SVG.defaultProps = {
  hasGradient: false,
  size: undefined,
  width: '100%',
  height: '100%',
  className: '',
  preserveAspectRatio: 'xMidYMid',
  fill: 'currentColor',
  verticalAlign: 'middle',
  styles: {},
}

export default SVG
