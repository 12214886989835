import { Gradient } from '@cinch/oxd-components/svg/src'

import './core.css'

import * as styles from './template.module.css'

const AppTemplate: React.FC = ({ children }) => {
  return (
    <div className={styles.app}>
      <Gradient />
      {children}
    </div>
  )
}

export default AppTemplate
