import { designTokens } from '@cinch-labs/design-system'
import * as styles from './svg.module.css'

const { color: palette } = designTokens.customProperties.ds

type GradientIDs = Record<string, string>

export const GRADIENT_IDS: GradientIDs = {
  alpha: 'svg-gradient-alpha',
  bravo: 'svg-gradient-bravo',
  charlie: 'svg-gradient-charlie',
  delta: 'svg-gradient-delta',
}

export const DEFAULT_GRADIENT_ID = 'alpha'

const Gradient: React.FC = () => (
  <svg className={styles.gradient} aria-hidden="true" focusable="false">
    <linearGradient id={GRADIENT_IDS.alpha} x2="1" y2="1">
      <stop offset="0%" stopColor={palette.deepPurple} />
      <stop offset="100%" stopColor={palette.pearlescentPink} />
    </linearGradient>
    <linearGradient id={GRADIENT_IDS.bravo} x2="1" y2="1">
      <stop offset="0%" stopColor={palette.iceBlue} />
      <stop offset="100%" stopColor={palette.goGreen} />
    </linearGradient>
    <linearGradient id={GRADIENT_IDS.charlie} x2="1" y2="1">
      <stop offset="0%" stopColor={palette.vividViolet} />
      <stop offset="100%" stopColor={palette.iceBlue} />
    </linearGradient>
    <linearGradient id={GRADIENT_IDS.delta} x2="1" y2="1">
      <stop offset="0%" stopColor={palette.vividViolet} />
      <stop offset="100%" stopColor={palette.warmOrange} />
    </linearGradient>
  </svg>
)

export default Gradient
