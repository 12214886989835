import Page from '@cinch/oxd-pages/maintenance/src'

const i18n = {
  heading: 'We are down for maintenance for a bit.',
  subHeading: 'Please check again soon.',
}

const MaintenancePage = () => <Page i18n={i18n} />

export default MaintenancePage
