import AppTemplate from '@cinch/oxd-components/app-template/src'
import { Brand, Facebook, Instagram, Twitter } from '@cinch/oxd-components/icons/src'
import SVG from '@cinch/oxd-components/svg/src'
import { Text, TextStyles } from '@cinch-labs/design-system'

import * as styles from './maintenance.module.css'

import animationWebm from './assets/animation.webm'

import placeholderImg from './assets/placeholder.png'

interface MaintenanceProps {
  i18n: Cinch.WebAppTranslations['maintenance']
}

const footerItems = [
  { title: 'Instagram', icon: Instagram, href: 'https://www.instagram.com/cinchuk' },
  { title: 'Facebook', icon: Facebook, href: 'https://www.facebook.com/cinchuk' },
  { title: 'Twitter', icon: Twitter, href: 'https://www.twitter.com/cinchuk' },
]

const MaintenancePage: React.FC<MaintenanceProps> = ({ i18n }) => {
  return (
    <AppTemplate>
      <div className={styles.page}>
        <header className={styles.header}>
          <SVG children={Brand} width={123} height={51} />
        </header>
        <section>
          <Text type={TextStyles.vehicleTitle} element="h1">
            {i18n.heading}
          </Text>
          <Text type={TextStyles.mainTitle} element="h2">
            {i18n.subHeading}
          </Text>
        </section>
        <section>
          <video className={styles.video} width={500} loop={true} autoPlay={true} muted={true} poster={placeholderImg}>
            <source src={animationWebm} type="video/webm" />
            <img
              width={500}
              height={300}
              src={placeholderImg}
              title="Your browser does not support the <video> tag"
              alt=""
            />
          </video>
          <img width={500} height={300} className={styles.fallback} src={placeholderImg} alt="" />
        </section>
        <footer>
          <ul className={styles.social}>
            {footerItems.map(({ href, icon, title }) => (
              <li key={title} className={styles.link}>
                <a href={href} title={title}>
                  <SVG children={icon} size={20} />
                </a>
              </li>
            ))}
          </ul>
        </footer>
      </div>
    </AppTemplate>
  )
}

export default MaintenancePage
